


































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { HomeProperties } from "@/components/home/HomeView.vue";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import TransferRequestService from "@/services/TransferRequestService";
import CompanyService from '@/services/CompanyService';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import ResponseUtil from "@/utilities/ResponseUtil";
import DateUtil from "@/utilities/DateUtil";

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-transfer.css" }]
	},
    data: () => ({
        recordCounts: [10, 20, 30, 40, 50],
        pageNo: 1,
        pageCount: 0,
        recordCount: 20,
        currency: {},
        requests: [],
        dateTimeFormat: null,
        loading: false
    })
})
export default class MobileTransferListView extends Vue {
    @Prop() private home: HomeProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private companyService = new CompanyService();
    private transferRequestService = new TransferRequestService();

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.$data.dateTimeFormat) : "-";
    }

    public formatStatus(v: any) {
        return this.$t("text." + v.toLowerCase());
    }

    public formatAmount(v: any) {
        const symbol = this.$data.currency.symbol ?? "";
        return symbol + " " + (v ?? 0).toFixed(2);
    }

    public async created() {
        await this.loadCurrency();
        await this.load();
    }

    public async loadCurrency() {
        try {
            const r = await this.companyService.currency();
            this.$data.currency = r.data;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async load() {
        const pageNo = this.$data.pageNo;
        const rowCount = this.$data.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.loading = false;

        try {
            const r = await this.transferRequestService.current({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
            });

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;
            const transferRequests = r.data.transferRequests;
            this.$data.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.$data.requests = transferRequests.sort((lhs, rhs) => lhs.id - rhs.id);
            await Vue.nextTick();
            this.$data.pageCount = count;
            if (pageNo > count) {
                this.$data.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }
}
